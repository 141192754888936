import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export function useCountdown(targetDate) {
  const activeTime = useSelector((store) => store.auth.activeTime);
  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft.seconds <= 0 && timeLeft.minutes <= 0) {
        clearInterval(timer);
      }
      else{
        setTimeLeft(calculateTimeLeft());
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return timeLeft;
}
