import { useEffect, useState } from "react";
import AllRoutes from "./Router/AllRoutes";
// import useGeolocationWithAllowedLocations from "./Utils/useGeoLocation";
import "./index.css";
import { sockets } from "./emitter";
import { useDispatch, useSelector } from "react-redux";
import { updateSettings } from "./Redux/AuthReducer/Action";
import { Box, Center, Container, Heading, Icon, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons"
import { useCountdown } from "./Utils/useCountDown";
function App() {
  const activeTime = useSelector((store) => store.auth.activeTime);
  const formatTime = (value) => value.toString().padStart(2, "0")
  const shutDownTime = useSelector((store) => store.auth.shutdownTime);
  let activetimeLeft = useCountdown(activeTime);
  let currentTime = Date.now();
  const dispatch = useDispatch();
  const fetchSettings = (time) => {
    dispatch(updateSettings(time));
  };
  useEffect(() => {
    sockets.on("settingsUpdate", fetchSettings);
    return () => {
      sockets.off("settingsUpdate", fetchSettings);
    };
  }, []);
  const [timeRemaining, setTimeRemaining] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const timeDifference = new Date(Number(shutDownTime)) - now;
      if (timeDifference <= 0) {
        clearInterval(interval);
        setTimeRemaining(0);
      } else {
        currentTime = Date.now();
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining(`${hours}H : ${minutes}M : ${seconds}S`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [shutDownTime]);
  return (
    <div>
      {currentTime > shutDownTime && currentTime > activeTime ? null : (
        <Box
          p={"10px 0px"}
          bgColor={"red"}
          w={"100%"}
          hidden={timeRemaining <= 0}
        >
          <Center>
            <Text>Shutdown in : {timeRemaining}</Text>
          </Center>
        </Box>
      )}
      {currentTime >= shutDownTime && currentTime <= activeTime ? (
        <Center minHeight="100vh" bg="gray.100">
          <Container maxW="md">
            <Box bg="white" p={8} borderRadius="lg" boxShadow="lg">
              <VStack spacing={6}>
                <Heading
                  size="lg"
                  textAlign="center"
                  display="flex"
                  alignItems="center"
                >
                  <Icon as={WarningIcon} color="yellow.500" mr={2} />
                  Site Under Update
                </Heading>
                <Text textAlign="center">
                  We're currently updating our site to bring you an even better
                  experience. Please check back soon!
                </Text>
                <SimpleGrid columns={4} spacing={4} width="100%">
                  {Object.entries(activetimeLeft).map(([unit, value]) => (
                    <Box
                      key={unit}
                      bg="gray.50"
                      p={4}
                      borderRadius="md"
                      textAlign="center"
                    >
                      <Text fontSize="2xl" fontWeight="bold">
                        {formatTime(value)}
                      </Text>
                      <Text
                        fontSize="11px"
                        color="gray.500"
                        textTransform="capitalize"
                      >
                        {unit}
                      </Text>
                    </Box>
                  ))}
                </SimpleGrid>
              </VStack>
            </Box>
          </Container>
        </Center>
      ) : <AllRoutes />}
    </div>
  );
}

export default App;
