import * as types from "./ActionTypes";
const initialState = {
  user: {},
  isLoading: false,
  isError: false,
  token: "",
  purchaseauth: "",
  activeTime: null,
  shutdownTime: null,
};

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SHEET_SETTING: {
      return {
        ...state,
        activeTime: payload.active,
        shutdownTime: payload.shutdown,
      };
    }
    case types.SIGNIN_REQUEST: {
      return { ...state, isLoading: true, isError: false };
    }
    case types.SIGNIN_SUCCESS: {
      return {
        ...state,
        user: payload.data,
        token: payload.token,
        isLoading: false,
        isError: false,
      };
    }
    case types.SIGNIN_FAILURE: {
      return { ...state, user: {}, isLoading: false, isError: true };
    }

    case types.SIGNOUT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.SIGNOUT_SUCCESS: {
      return {
        ...state,
        user: {},
        token: "",
        purchaseauth: "",
        isLoading: false,
        isError: false,
      };
    }
    case types.SIGNOUT_FAILURE: {
      return { ...state, user: {}, token: "", isLoading: false, isError: true };
    }
    case types.PURCHASE_AUTH: {
      return { ...state, purchaseauth: payload };
    }
    default:
      return state;
  }
};
