import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { sockets } from "../emitter";
import {
  addToNotification,
  updateNotification,
} from "../Redux/AppReducer/Action";
import Permissions from "./Permissions";
const ProfileButton = ({ handleLogin }) => {
  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const user = useSelector((store) => store.auth.user);
  const dispatch = useDispatch();
  const localS = useSelector((store) => store.app.notifyData);
  useEffect(() => {
    sockets.on("notification", (data) => {
      dispatch(updateNotification(true));
      if (localS.length <= 0) {
        dispatch(addToNotification(data));
      } else {
        const id = localS[localS.length - 1].id;
        let newData = data.filter((e) => e.id > id);
        localS.forEach((e) => {
          e.read = true;
          newData.push(e);
        });
        newData = newData.sort((a, b) => b.id - a.id);
        dispatch(addToNotification(newData));
      }
    });
    sockets.on("first_notification", (data) => {
      if (data.length > localS.length) {
        dispatch(updateNotification(true));
        if (localS.length <= 0) {
          dispatch(addToNotification(data));
        } else {
          const id = localS[localS.length - 1].id;
          let newData = data.filter((e) => e.id > id);
          localS.forEach((e) => {
            e.read = true;
            newData.push(e);
          });
          newData = newData.sort((a, b) => b.id - a.id);
          dispatch(addToNotification(newData));
        }
      }
    });
  }, []);
  return (
    <Flex hidden={user.first_name==undefined}>
      <Permissions isUpdateOpen={isUpdateOpen} onUpdateClose={onUpdateClose} />
      <Menu
        hidden={user.first_name == undefined}
        placement="bottom-end"
        zIndex={1000}
      >
        <MenuButton
          fontSize={"12px"}
          as={Button}
          hidden={user.first_name == undefined}
        >
          {user.first_name}
        </MenuButton>
        <MenuList fontSize={"14px"}>
          {user.role == "admin" ? (
            <MenuItem
              _hover={{ bgColor: "blue", color: "white" }}
              onClick={() => {
                onUpdateOpen();
              }}
            >
              User Permissions
            </MenuItem>
          ) : null}
          <MenuItem
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/purchase")}
          >
            Add Purchase
          </MenuItem>
          <MenuItem
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/sales")}
          >
            Add Sales
          </MenuItem>
          <MenuItem
            hidden={window.innerWidth <= 1000}
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/products")}
          >
            Products
          </MenuItem>
          <MenuItem
            hidden={window.innerWidth <= 1000}
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/feedback")}
          >
            Client Feedback
          </MenuItem>
          <MenuItem
            _hover={{ bgColor: "orange", color: "white" }}
            onClick={() => {
              navigate("/how-to-videos");
            }}
          >
            How To Use?
          </MenuItem>
          <MenuItem
            _hover={{ bgColor: "red", color: "white" }}
            onClick={handleLogin}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ProfileButton;
