export const table_filter = {
  active: [
    "order received",
    "active",
    "cutting",
    "stitching",
    "logo designing",
    "logo printing",
    "logo stitching",
    "embroydary",
    "packing",
    "tag labeling",
    "ironing",
    "knot stitching",
    "buckle assembling",
    "lamination",
    "knot making",
  ],
  hold: ["hold", "return from dispatchment"],
  queueDispatch: ["queue for dispatchment"],
  payment: ["payment pending", "full payment", "partial payment"],
  readyDispatch: [
    "ready for dispatchment",
    "shipping booked",
    "update shipping",
    "shipping cancelled",
  ],
  shipped: ["pickup complete"],
  complete: ["delivered", "self pickup"],
  cancel: ["cancel", "cancelled by courier"],
};
export const filter_stage_name = [
  "active",
  "hold",
  "queueDispatch",
  "payment",
  "readyDispatch",
  "shipped",
  "complete",
  "cancel",
];
export const universal_stages = [
  { name: " Order Received", value: "order received", hidden: true },
  { name: "Hold", value: "hold", blockedtab: "1" },
  { name: "Active", value: "active", blockedtab: "03" },
  { name: "Cancel", value: "cancel", blockedtab: "" },
  { name: "------------", value: "------------", label: true },
  { name: "Cutting", value: "cutting", blockedtab: "13" },
  { name: "Stitching", value: "stitching", blockedtab: "13" },
  { name: "Logo Designing", value: "logo designing", blockedtab: "13" },
  { name: "Logo Printing", value: "logo printing", blockedtab: "13" },
  { name: "Logo Stitching", value: "logo stitching", blockedtab: "13" },
  { name: "Embroydary", value: "embroydary", blockedtab: "13" },
  { name: "Packing", value: "packing", blockedtab: "13" },
  { name: "Tag Labeling", value: "tag labeling", blockedtab: "13" },
  { name: "Ironing", value: "ironing", blockedtab: "13" },
  { name: "Knot Stitching", value: "knot stitching", blockedtab: "13" },
  { name: "Buckle Assembling", value: "buckle assembling", blockedtab: "13" },
  { name: "Lamination", value: "lamination", blockedtab: "13" },
  { name: "Knot Making", value: "knot making", blockedtab: "13" },
  { name: "------------", value: "------------", label: true },
  {
    name: "Queue For Dispatchment",
    value: "queue for dispatchment",
    blockedtab: "13",
  },
  {
    name: "Ready For Dispatchment",
    value: "ready for dispatchment",
    blockedtab: "013",
  },
  {
    name: "Return From Dispatchment",
    value: "return from dispatchment",
    blockedtab: "013",
  },
  { name: "------------", value: "------------", label: true },
  { name: "Self Pickup", value: "self pickup", blockedtab: "01" },
  { name: "Shipping Booked", value: "shipping booked", blockedtab: "01" },
  { name: "Shipping Cancelled", value: "shipping cancelled", blockedtab: "01" },
  { name: "Update Shipping", value: "update shipping", hidden: true },
  { name: "Pickup Complete", value: "pickup complete", blockedtab: "01" },
  {
    name: "Cancelled By Courier",
    value: "cancelled by courier",
    blockedtab: "01",
  },
  { name: "Delivered", value: "delivered", blockedtab: "01" },
];
export const hold_list = [
  { name: "Select Reson", hidden: true },
  { name: "40-50% advance payment has not been received yet" },
  { name: "Cloth Manufacturing" },
  { name: "Not Receive Sample" },
  { name: "Others" },
];
export const courier_partner = [
  "SAFEXPRESS",
  "DELEX",
  "SPOTON",
  "TCI EXPRESS",
  "OXYZEN",
  "MUDITA",
  "SCORPION EXPRESS",
  "WATSOO",
  "BLUE DART",
  "DELHIVERY",
  "ECOM EXPRESS",
  "XPRESS BEES",
  "SHADOWFAX",
  "DTDC",
  "E-KART",
  "AMAZON",
  "GATI",
  "SMARTR",
  "MOVIN",
  "VALMO",
  "VRL",
  "TRANSPORT",
];
export const exclude_stage = ["product modified", "delete"];
export const user_permission = [
  {
    name: "add product",
    checked: false,
    value: "isAddProduct",
  },
  {
    name: "edit product",
    checked: false,
    value: "isEditProduct",
  },
  {
    name: "delete product",
    checked: false,
    value: "isDeleteProduct",
  },
  {
    name: "show logs",
    checked: false,
    value: "isShowLogs",
  },
  {
    name: "update shipping",
    checked: false,
    value: "isUpdateShipping",
  },
  {
    name: "generate report",
    checked: false,
    value: "isGenerateReport",
  },
  {
    name: "update permission",
    checked: false,
    value: "isUpdatePermission",
  },
];
export const allowed_stages_for_new = [
  "order received",
  "active",
  "hold",
  "return from dispatchment",
  "ready for dispatchment",
  "pickup complete",
  "delivered",
  "self pickup",
];
export const admin_user = [
  "santanu.dey@rumaenterprise.com",
  "md@rumaenterprise.com",
  "anish@gmail.com",
];
export const deepColorHexArray = [
  "#4B0082", // Indigo
  "#8B0000", // Dark Red
  "#006400", // Dark Green
  "#00008B", // Dark Blue
  "#8B008B", // Dark Magenta
  "#2F4F4F", // Dark Slate Gray
  "#556B2F", // Dark Olive Green
  "#B22222", // Firebrick
  "#FF4500", // Orange Red
  "#191970", // Midnight Blue
  "#800000", // Maroon
  "#808000", // Olive
  "#483D8B", // Dark Slate Blue
  "#6B8E23", // Olive Drab
  "#C71585", // Medium Violet Red
  "#4682B4", // Steel Blue
  "#A0522D", // Sienna
  "#2E8B57", // Sea Green
  "#D2691E", // Chocolate
  "#5F9EA0", // Cadet Blue
  "#8B4513", // Saddle Brown
  "#7B68EE", // Medium Slate Blue
  "#9ACD32", // Yellow Green
  "#20B2AA", // Light Sea Green
  "#8A2BE2", // Blue Violet
  "#A52A2A", // Brown
  "#B8860B", // Dark Goldenrod
  "#9932CC", // Dark Orchid
  "#CD5C5C", // Indian Red
  "#4B5320", // Army Green
];
