import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../Redux/AuthReducer/Action";
import usePrivate from "../Utils/usePrivate";

const Login = ({ onLoginClose }) => {
  const { KMS } = usePrivate();
  const toast = useToast();
  const dispatch = useDispatch();
  const email = useRef(null);
  const pass = useRef(null);
  const remember = useRef(null);
  const loginLoad = useSelector((store) => store.auth.isLoading);
  const enc_localKey = localStorage.getItem("Rumalogin") || "";
  const localKey = KMS(enc_localKey, 2);
  const login = async () => {
    try {
      const emailData = email.current.value;
      const passData = pass.current.value;
      if (emailData != "" && passData != "") {
        const payload = {
          email: emailData,
          password: passData,
        };
        dispatch(signin(payload, toast, onLoginClose, remember));
      } else {
        toast({
          title: "Fill All Fields",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Flex align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    pass.current.focus();
                  }
                }}
                defaultValue={localKey.email}
                ref={email}
                type="email"
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    login();
                  }
                }}
                defaultValue={localKey.password}
                ref={pass}
                type="password"
              />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox defaultChecked={localKey.remember} ref={remember}>
                  Remember me
                </Checkbox>
              </Stack>
              <Button
                isLoading={loginLoad}
                onClick={login}
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
