import { Box, Center, Flex, Select, Text } from "@chakra-ui/react";
import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import TableCard from "./TableCard";
import "./table.css";
import { Link, useSearchParams } from "react-router-dom";
import { universal_stages } from "../universal_variable";

const TableForm = (prop) => {
  const {
    handleUpdateOrder,
    onProgressOpen,
    onEditOpen,
    setProgress,
    tabIndex,
    onDeleteProductOpen,
  } = prop;
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  let production_sheet = useSelector((store) => store.app.orderList);
  if (tabIndex == 3) {
    production_sheet = production_sheet.sort((a, b) => b.id - a.id);
  }
  return (
    <Box mt={window.innerWidth <= 800 ? "40px" : "10px"}>
      <link rel="stylesheet" type="text/css" href={"./table.css"} />
      {production_sheet.length > 0 ? (
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th w="100px" border="1px solid black" p="5px">
                <Center>ID No.</Center>
              </th>
              <th w="100px" border="1px solid black" p="5px">
                <Center>Order Data</Center>
              </th>
              <th>
                <Center>Order Description</Center>
              </th>
              <th style={{ width: "150px" }}>
                <Center>Order Receiving Date</Center>
              </th>
              <th style={{ width: "150px" }}>
                <Center>Expected Delivery Date</Center>
              </th>
              {tab <= 3 || tab == 7 ? null : (
                <th>
                  <Center>AWB No.</Center>
                </th>
              )}
              {tab <= 3 || tab == 7 ? null : (
                <th>
                  <Center>Delivery Partner</Center>
                </th>
              )}
            </tr>
          </thead>
          <tbody borderTop="1px solid black">
            {production_sheet?.map((ele, i) => {
              return (
                <TableCard
                  key={i}
                  prop={prop}
                  ele={ele}
                  handleUpdateOrder={handleUpdateOrder}
                  onProgressOpen={onProgressOpen}
                  onEditOpen={onEditOpen}
                  setProgress={setProgress}
                  tabIndex={tabIndex}
                  onDeleteProductOpen={onDeleteProductOpen}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <Center>
          <Text fontSize={"20px"} fontWeight={"bold"} mt={"15%"}>
            No data found
          </Text>
        </Center>
      )}
    </Box>
  );
};

export default React.memo(TableForm);